<template>
  <main class="content">
    <b-overlay :show="loaded" rounded="sm">
      <template #overlay>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Vui lòng chờ...</strong>
        </div>
      </template>
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-9 d-none d-sm-block">
            <h3>
              <b-icon icon="person-lines-fill" aria-hidden="true"></b-icon>
              Danh sách nhân viên
            </h3>
          </div>
          <div class="col-3 d-none d-sm-block">
            <b-button variant="primary" class="mb-2" @click="openAddModal()">
              Thêm mới <b-icon icon="person-plus" aria-hidden="true"></b-icon>
            </b-button>
          </div>
        </div>
        <hr />
        <div class="col-12 col-xl-12">
          <b-container fluid class="my-1">
            <b-row>
              <b-col class="my-1">
                <b-form-group
                  label="Bộ lọc"
                  label-for="filter-input"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Xoá</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col class="my-1">
                <b-form-group
                  label="Số nhân viên hiển thị"
                  label-for="per-page-select"
                  class="mb-0"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Main table element -->
            <b-table
              :items="users"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              @filtered="onFiltered"
              :striped="true"
              :bordered="true"
              :borderless="true"
              :outlined="true"
              :hover="true"
              :fixed="true"
            >
              <template #cell(role)="row">
                <b-badge variant="danger" v-if="row.value === 'manage'"
                  >Quản lý</b-badge
                >
                <b-badge variant="success" v-else>Nhân viên</b-badge>
              </template>

              <template #cell(actions)="row">
                <b-button
                  size="sm"
                  class="mb-2"
                  @click="showEditModal(row.item)"
                >
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon> Sửa
                </b-button>
                <b-button
                  size="sm"
                  variant="danger"
                  class="mb-2"
                  @click="onDelete(row.item)"
                >
                  <b-icon icon="trash" aria-hidden="true"></b-icon> Xoá
                </b-button>
              </template>
            </b-table>
            <b-col sm="4" md="4" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
              ></b-pagination>
            </b-col>
            <b-modal
              :id="user.user_id"
              title="Thông tin nhân viên"
              hide-backdrop
              centered
              content-class="shadow"
              ok-only
              hide-footer
              @hide="resetUserModal()"
            >
              <form @submit.prevent="onSave()" method="post">
                <div
                  class="alert alert-danger alert-dismissible"
                  role="alert"
                  v-if="error"
                >
                  <div class="alert-message">
                    <strong>Lỗi!</strong> {{ error }}
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-form-label col-sm-4">Họ và tên</label>
                  <div class="col-sm-8">
                    <input
                      v-model="user.full_name"
                      class="form-control"
                      placeholder="Họ và tên"
                      :class="[$v.user.full_name.$error ? 'input-error' : '']"
                    />
                    <div v-if="submitted && $v.user.full_name.$error">
                      <p
                        class="invalid-feedback"
                        style="text-left"
                        v-if="!$v.user.full_name.required"
                      >
                        Không được để trống!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-form-label col-sm-4">Số điện thoại</label>
                  <div class="col-sm-8">
                    <input
                      v-model="user.phone"
                      class="form-control"
                      placeholder="Số điện thoại"
                      :class="[$v.user.phone.$error ? 'input-error' : '']"
                    />
                    <div v-if="submitted && $v.user.phone.$error">
                      <p
                        class="invalid-feedback"
                        style="text-left"
                        v-if="!$v.user.phone.required"
                      >
                        Không được để trống!
                      </p>
                      <p
                        class="invalid-feedback"
                        style="text-left"
                        v-if="!$v.user.phone.numeric"
                      >
                        Số điện thoại không hợp lệ!
                      </p>
                    </div>
                  </div>
                </div>
                <fieldset class="mb-3">
                  <div class="row">
                    <label class="col-form-label col-sm-4 pt-sm-0">Phân quyền</label>
                    <div class="col-sm-8">
                      <label class="form-check">
                        <input
                          name="radio-3"
                          v-model="user.role"
                          type="radio"
                          class="form-check-input"
                          value="manage"
                        />
                        <span class="form-check-label">Quản lý</span>
                      </label>
                      <label class="form-check">
                        <input
                          v-model="user.role"
                          name="radio-3"
                          type="radio"
                          class="form-check-input"
                          value="employee"
                        />
                        <span class="form-check-label">Nhân viên</span>
                      </label>
                    </div>
                  </div>
                </fieldset>
                <div class="mb-3 row">
                  <div class="col-sm-8 ms-sm-auto">
                    <button type="submit" class="btn btn-primary">Lưu</button>
                  </div>
                </div>
              </form>
            </b-modal>
          </b-container>
        </div>
      </div>
    </b-overlay>
  </main>
</template>

<script>
import { mapState } from "vuex"
import { required, numeric } from "vuelidate/lib/validators"

export default {
  data() {
    return {
      loaded: true,
      fields: [
        {
          key: "user.full_name",
          label: "Họ và tên"
        },
        {
          key: "user.phone",
          label: "Số điện thoại",
          class: "text-center"
        },
        {
          key: "role",
          label: "Phân quyền"
        },
        { key: "actions", label: "Hành động" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      filter: null,
      filterOn: [],
      user: {
        user_id: "add",
        full_name: "",
        role: "employee",
        phone: ""
      },
      alertMsg: null,
      submitted: false
    }
  },
  validations: {
    user: {
      phone: { required, numeric },
      full_name: { required },
      role: { required }
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo,
      users: (state) => state.user.users,
      error: (state) => state.user.error
    })
  },
  async mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("user/get")
      this.totalRows = this.users.length
      this.loaded = false
    },
    resetUserModal() {
      this.user.user_id = "add"
      this.user.full_name = ""
      this.user.role = "employee"
      this.user.phone = ""
      this.$v.user.$reset()
      this.$store.dispatch("user/setError", "")
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showEditModal(user) {
      this.user = {
        user_id: user.user_id,
        full_name: user.user.full_name,
        role: user.role,
        phone: user.user.phone
      }
      setTimeout(() => this.$bvModal.show(this.user.user_id), 100)
    },
    async onDelete(user) {
      await this.$bvModal
        .msgBoxConfirm("Do you want to delete this employee?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          hideBackdrop: true,
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then((value) => {
          if (value) this.deleteUser(user.user_id)
        })
        .catch(() => {
          // An error occurred
        })
    },
    async deleteUser(userId) {
      this.loaded = true
      await this.$store.dispatch("user/delete", userId)
      this.loaded = false
    },
    async onSave() {
      this.submitted = true
      this.$v.user.$touch()
      if (this.$v.user.$error) return
      this.loaded = true
      let params = {
        user_id: this.user.user_id,
        phone: this.user.phone,
        full_name: this.user.full_name,
        role: this.user.role
      }
      if (this.user.user_id === "add") {
        await this.$store.dispatch("user/post", params)
      } else {
        await this.$store.dispatch("user/put", params)
      }
      if (!this.error) {
        this.$bvModal.hide(this.user.user_id)
      }
      this.loaded = false
    },
    openAddModal() {
      this.user = {
        user_id: "add",
        full_name: "",
        role: "employee",
        phone: ""
      }
      setTimeout(() => this.$bvModal.show("add"), 100)
    }
  }
}
</script>

<style lang="css">
.input-error {
  border-color: #dc3545 !important;
}
.invalid-feedback {
  display: block !important;
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
}
</style>