<template>
  <main class="content">
    <loading :isLoading="loaded" />
    <div class="container-fluid p-0">
      <div class="row mb-2 mb-xl-3">
        <div class="col-auto d-none d-sm-block text-center">
          <h3>Thông tin đơn hàng</h3>
        </div>
      </div>
    </div>
    <hr />
    <form @submit.prevent="onSave()" method="post">
      <div
        class="alert alert-danger alert-dismissible"
        role="alert"
        v-if="error"
      >
        <div class="alert-message">
          <strong>Xin chào!</strong> {{ error }}
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Trạng thái đơn hàng</label>
        <div class="col-sm-10">
          <select
            class="form-control"
            v-model="form.order_status_id"
            :disabled="form.order_status_id === 1 || form.order_status_id === 3"
          >
            <option :value="status.id" v-for="(status, index) in orderStatus" :key="index">{{status.name}}</option>
          </select>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Mã đơn hàng</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.order.code"
            disabled
          />
        </div>
      </div>
       <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Mã vận chuyển</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.code"
            :placeholder="[form.code ? form.code : 'Vui lòng đóng gói và giao cho EPIS Post để có được mã vận chuyển này.']"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Họ và tên</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            :placeholder="form.order.customer_name"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Email</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            :placeholder="form.order.customer_email"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Địa chỉ</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.order.customer_address"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Tỉnh/Thành phố</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.order.customer_province_id"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Quận/Huyện</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.order.customer_district_id"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Phường/Xã</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.order.customer_ward_id"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Số điện thoại</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.order.customer_phone"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Ngày tạo đơn hàng</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.order.order_date"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Chi tiết đơn hàng</label>
        <div class="col-sm-10">
          <div class="table-responsive">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Tên</th>
                  <th scope="col">Giá</th>
                  <th scope="col">Số lượng</th>
                  <th scope="col">Tổng cộng</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in order.details" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ item.product.name }}</td>
                  <td>{{ item.product.regular_price }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.amount }}</td>
                </tr>
                <tr>
                  <th colspan="3"></th>
                  <td>Tổng số tiền</td>
                  <td>{{ order.price }}</td>
                </tr>
                <tr>
                  <th colspan="3"></th>
                  <td>Phí vận chuyển</td>
                  <td>{{ order.fee }}</td>
                </tr>
                <tr>
                  <th colspan="3"></th>
                  <td>Tổng</td>
                  <td>{{ parseFloat(order.price) + parseFloat(order.fee) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-sm-10 ms-sm-auto">
          <button type="submit" class="btn btn-success">Lưu lại</button>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      form: {
        id: 0,
        order: {
          customer_name: '',
          customer_email: '',
          customer_phone: '',
          customer_address: '',
          customer_province_id: '',
          customer_district_id: '',
          customer_ward_id: ''
        }
      },
      loaded: true,
      submitted: false,
    };
  },
  validations: {
    form: {
      order_status_id: { required },
    },
  },
  computed: {
    ...mapState({
      error: (state) => state.order.error,
      order: (state) => state.order.order,
      orderStatus: (state) => state.order.orderStatus,
    }),
  },
  async mounted() {
    await this.getDetail(this.$route.params.id);
    this.form = this.order
    this.loaded = false;
  },
  methods: {
    async getDetail(id) {
      await this.$store.dispatch("order/getStatus");
      await this.$store.dispatch("order/detail", id);
    },
    async onSave() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.loaded = true;
      const params = {
        id: this.$route.params.id,
        order_status_id: this.form.order_status_id,
        code: this.form.code
      };
      await this.$store.dispatch("order/put", params);
      if (!this.error) {
        //this.$router.push("/orders");
      }
      this.loaded = false;
    },
  },
};
</script>
