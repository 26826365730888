import AuthRepository from '../../repositories/AuthRepository'
import Routes from '../../routes'

const state = {
  token: '',
  refreshToken: '',
  errorLogin: '',
  errorSendOtp: '',
  role: '',
  userInfo: {}
}

const getters = {
}

const actions = {
  logout ({ commit }) {
    commit('logout')
  },
  resetErrorLogin ({ commit }) {
    commit('setErrorLogin', '')
    commit('setErrorSendOtp', '')
  },
  async sendOtp ({ commit }, data) {
    await AuthRepository.sendOtp(data)
      .then(() => {
        commit('setErrorSendOtp', '')
      })
      .catch(error => {
        console.log(error.response)
        let errMsg = 'Network Error'
        if (error.response.data) {
          errMsg = 'The phone number is not exist'
        }
        commit('setErrorSendOtp', errMsg)
      })
  },
  async login ({ commit }, data) {
    await AuthRepository.login(data)
      .then(resp => {
        const { token, refresh_token } = resp.data.data
        commit('setErrorLogin', '')
        commit('setToken', token)
        commit('setRefreshToken', refresh_token)
      })
      .catch(error => {
        let errMsg = 'Network Error'
        if (error.response.data) {
          errMsg = 'Invalid OTP code'
        }
        commit('setErrorLogin', errMsg)
      })
  },
  async getUserInfo ({ commit }) {
    await AuthRepository.getUserInfo()
      .then(resp => {
        commit('setUserInfo', resp.data.data)
      })
  },
  async refreshToken ({ commit, state }) {
    const data = {
      refresh_token: state.refreshToken
    }
    await AuthRepository.refreshToken(data)
      .then( res => {
        const { access_token, refresh_token } = res.data
        commit('setToken', access_token)
        commit('setRefreshToken', refresh_token)
      })
      .catch( () => {
        commit('logout')
        Routes.push('/login')
      })
  }
}

const mutations = {
  setToken (state, data) {
    state.token = data
  },
  setRefreshToken (state, data) {
    state.refreshToken = data
  },
  setErrorLogin(state, msg){
    state.errorLogin = msg
  },
  setErrorSendOtp(state, msg){
    state.errorSendOtp = msg
  },
  logout(state) {
    state.token = ''
    state.refreshToken = ''
  },
  setUserInfo(state, data) {
    state.userInfo = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
