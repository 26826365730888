import OrderRepository from "../../repositories/OrderRepository";

const state = {
  orders: [],
  order: {},
  orderStatus: [],
  error: ""
};

const getters = {};

const actions = {
  async get({ commit }) {
    await OrderRepository.get().then(resp => {
      commit("setOrders", resp.data.data);
    });
  },
  async getStatus({ commit }) {
    await OrderRepository.getStatus().then(resp => {
      commit("setOrderStatus", resp.data.data);
    });
  },
  async detail({ commit }, id) {
    await OrderRepository.detail(id).then(resp => {
      commit("setOrder", resp.data.data);
    });
  },
  async put({ commit, dispatch }, payload) {
    await OrderRepository.put(payload).then(() => {
      commit("setError", "");
      dispatch("get");
    });
  },
  async post({ commit }, payload) {
    await OrderRepository.post(payload)
      .then(() => {
        commit("setError", "");
      })
      .catch(error => {
        commit("setError", error.response.data.message);
      });
  },
  async delete({ commit, dispatch }, id) {
    await OrderRepository.delete(id).then(() => {
      commit("setError", "");
      dispatch("get");
    });
  },
  setError({ commit }) {
    commit("setError", "");
  }
};

const mutations = {
  setOrders(state, data) {
    state.orders = data;
  },
  setOrderStatus(state, data) {
    state.orderStatus = data;
  },
  setOrder(state, data) {
    state.order = data;
  },
  setError(state, msg) {
    state.error = msg;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
