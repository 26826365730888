
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import { isExpiredToken } from './helpers'

import Home from './views/Home'
import Login from './views/Auth/Login'
import Profile from './views/Auth/Profile'
import ShopInfo from './views/ShopInfo'
import NotFound from './views/NotFound'
import CheckoutHistory from './views/CheckoutHistory'
import ProductForm from './views/Product/Form'
import ProductList from './views/Product/List'
import UserList from './views/User/List'
import OrderForm from './views/Order/Form'
import OrderList from './views/Order/List'
Vue.use(VueRouter)

const routes = [
  {
    name: 'home',
    path: '/',
    component: Home,
    meta: {
      layout: 'member',
      auth: true
    }
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.state.auth.token) {
        next('/')
      }
      next()
    },
    meta: {
      layout: 'login',
      auth: undefined
    }
  },
  {
    name: 'profile',
    path: '/profile',
    component: Profile,
    meta: {
      layout: 'member',
      auth: true
    },
  },
  {
    name: 'shop-info',
    path: '/shop-info',
    component: ShopInfo,
    meta: {
      layout: 'member',
      auth: true
    },
  },
  {
    name: 'product-add',
    path: '/product/add',
    component: ProductForm,
    meta: {
      layout: 'member',
      auth: true
    },
  },
  {
    name: 'product-edit',
    path: '/product/edit/:id',
    component: ProductForm,
    meta: {
      layout: 'member',
      auth: true
    },
  },
  {
    name: 'product-import',
    path: '/product/import',
    component: ProductForm,
    meta: {
      layout: 'member',
      auth: true
    },
  },
  {
    name: 'product-list',
    path: '/products',
    component: ProductList,
    meta: {
      layout: 'member',
      auth: true
    },
  },
  {
    name: 'order-edit',
    path: '/order/edit/:id',
    component: OrderForm,
    meta: {
        layout: 'member',
        auth: true
    },
  },
  {
      name: 'order-list',
      path: '/orders',
      component: OrderList,
      meta: {
          layout: 'member',
          auth: true
      },
  },
  {
    name: 'user-list',
    path: '/users',
    component: UserList,
    meta: {
      layout: 'member',
      auth: true
    },
  },
  {
    name: 'checkout-history',
    path: '/checkout/history',
    component: CheckoutHistory,
    meta: {
      layout: 'member',
      auth: true
    },
  },
  {
    path: '/404',
    component: NotFound,
    meta: {
        auth: undefined,
        layout: 'login'
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active"
})

router.beforeEach(async(to, from, next) => {
  if(['login', 'register'].includes(to.name) && store.state.auth.token) {
    next('/')
  }
  if(to.matched.some(record => record.meta.auth)) {
    if (store.state.auth.token) {
      if(!isExpiredToken()) {
        await store.dispatch('auth/refreshToken')
      }
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
