import store from './store'
import Vue from 'vue'


Vue.directive("role", {
  inserted(el, binding) {
    const { value } = binding
    const role = store.state.auth.userInfo.role
    if (value) {
      const requiredRoles = value
      const hasRole = requiredRoles === role

      if (!hasRole) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(`Roles are required!`)
    }
  }
})
