import store from "./store"
import moment from "moment"

const isExpiredToken = () => {
  const token = store.state.auth.token
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  );

  const decodeToken = JSON.parse(jsonPayload);
  const timeNow = moment().valueOf() / 1000;

  if (decodeToken.exp >= timeNow) {
    return true
  }
  return false
}

export { isExpiredToken }
