<template>
  <main class="content">
    <div class="container-fluid p-0">
      <div class="mb-3">
        <h1 class="h3 d-inline align-middle"><i class="align-middle me-2 fas fa-fw fa-store-alt"></i> {{ userInfo.shopInfo.name }}</h1>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0">Thông tin cửa hàng</h5>
            </div>
            <div class="card-body">
              <table class="table table-sm mt-2 mb-4">
                <tbody>
                  <tr>
                    <th>Shop ID</th>
                    <td> {{ userInfo.shopInfo.id }}</td>
                  </tr>
                  <tr>
                    <th>Tên cửa hàng</th>
                    <td> {{ userInfo.shopInfo.name }}</td>
                  </tr>
                  <tr>
                    <th>Địa chỉ</th>
                    <td>{{ userInfo.shopInfo.address }}</td>
                  </tr>
                  <tr>
                    <th>Striple Code</th>
                    <td>{{ userInfo.shopInfo.code }}</td>
                  </tr>
                  <tr>
                    <th>Số điện thoại</th>
                    <td>{{ userInfo.shopInfo.phone }}</td>
                  </tr>
                  <tr>
                    <th>Trạng thái</th>
                    <td><span class="badge bg-success">Hoạt động</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex"
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo
    })
  }
}
</script>