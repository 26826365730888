<template>
  <main class="content">
    <loading :isLoading="loaded" />

    <b-modal id="choose-type" size="sm" title="Chọn kiểu tạo sản phẩm" no-stacking hide-backdrop centered >
      <select class="form-control mb-3" v-model="addType">
        <option value="1">Tạo bằng tay</option>
        <option value="2">Import link</option>
      </select>
      <template #modal-footer="">
        <b-button size="sm" variant="success" @click="onChooseType()">
          OK
        </b-button>
        <b-button size="sm" variant="danger" @click="$bvModal.hide('choose-type')">
          Huỷ
        </b-button>
      </template>
    </b-modal>

    <b-modal id="import-link" title="Tạo bằng link" hide-backdrop centered hide-footer>
      <form @submit.prevent="onCrawl()" method="post">
        <div class="mb-3 row">
          <label class="col-form-label col-sm-2 text-sm-end">URL</label>
          <div class="col-sm-10">
            <input type="url" class="form-control" placeholder="Link sản phẩm cần lấy" v-model="form.url">
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-form-label col-sm-2 text-sm-end">Gram</label>
          <div class="col-sm-10">
            <input type="number" class="form-control" placeholder="Khối lượng" v-model="form.gram" min="1">
          </div>
        </div>
        <div class="mb-3 row">
          <div class="col-sm-10 ms-sm-auto">
            <button type="submit" class="btn btn-primary">Bắt đầu lấy dữ liệu</button>
          </div>
        </div>
      </form>
    </b-modal>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-9 d-none d-sm-block">
          <h3><b-icon icon="list" aria-hidden="true"></b-icon> Danh sách sản phẩm</h3>
        </div>
        <div class="col-3 d-none d-sm-block">
          <b-button variant="primary" class="mb-2" @click="$bvModal.show('choose-type')">
            Thêm mới
          </b-button>
        </div>
      </div>
      <hr />
      <div class="col-12 col-xl-12">
        <b-container fluid class="my-1">
          <b-row>
            <b-col class="my-1">
              <b-form-group
                label="Bộ lọc"
                label-for="filter-input"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Nhập từ khoá..."
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Xoá</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col class="my-1">
              <b-form-group
                label="Số sản phẩm hiển thị"
                label-for="per-page-select"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Main table element -->
          <b-table
            :items="products"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
            @filtered="onFiltered"
            :striped="true"
            :bordered="true"
            :borderless="true"
            :outlined="true"
            :hover="true"
            :fixed="true"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(avatar)="row">
              <img :src="row.value" alt="" width="80" height="80" />
            </template>
            <template #cell(categories)="row">
              <p v-for="(category, index) in row.value" :key="index">
                <b-badge variant="info"> {{ category.name }}</b-badge>
              </p>
            </template>
            <template #cell(regular_price)="row">
              {{ row.value | vnd() }}
            </template>
            <template #cell(name)="row">
              {{ row.value }}
            </template>
            <template #cell(is_public)="row">
              <b-badge variant="danger" v-if="!row.value">Không hiển thị</b-badge>
              <b-badge variant="success" v-else>Hiển thị</b-badge>
            </template>

            <template #cell(actions)="row">
              <b-button size="sm" class="mb-2" @click="onEdit(row.item.id)">
                <b-icon icon="pencil-square" aria-hidden="true"></b-icon> Sửa
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                class="mb-2"
                @click="onDelete(row.item)"
              >
                <b-icon icon="trash" aria-hidden="true"></b-icon> Xoá
              </b-button>
            </template>
          </b-table>
          <b-col sm="4" md="4" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
            ></b-pagination>
          </b-col>
        </b-container>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex"

export default {
  data() {
    return {
      isBusy: true,
      fields: [
        {
          key: "avatar",
          label: "Hình ảnh"
        },
        {
          key: "name",
          label: "Tên"
        },
        {
          key: "categories",
          label: "Thể loại"
        },
        {
          key: "regular_price",
          label: "Giá"
        },
        {
          key: "is_public",
          label: "Trạng thái"
        },
        { key: "actions", label: "Hành động" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      filter: null,
      filterOn: [],
      loaded: false,
      addType: 1,
      form: {
        url: '',
        gram: ''
      }
    }
  },
  computed: {
    ...mapState({
      products: (state) => state.product.products
    })
  },
  async mounted() {
    this.fetchData()
  },
  filters: {
    vnd: function (value) {
      return value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
    }
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("product/get")
      this.totalRows = this.products.length
      this.isBusy = false
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async onDelete(product) {
      await this.$bvModal
        .msgBoxConfirm("Bạn có muốn xoá sản phẩm này?", {
          title: "Vui lòng xác nhận",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Xoá",
          cancelTitle: "Huỷ bỏ",
          hideBackdrop: true,
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then((value) => {
          if (value) this.deleteProduct(product.id)
        })
        .catch(() => {
          // An error occurred
        })
    },
    async deleteProduct(id) {
      this.loaded = true
      await this.$store.dispatch("product/delete", id)
      this.loaded = false
    },
    onChooseType() {
      if (this.addType === 1) this.$router.push("/product/add")
      else {
        this.$bvModal.show('import-link')
      }
    },
    onEdit(id) {
      this.$router.push("/product/edit/" + id)
    },
    onCrawl() {
      this.$router.push({ path: '/product/add', query: { url: this.form.url, gram: this.form.gram }})
    }
  }
}
</script>