<template>
  <div class="wrapper">
    <Navbar />
    <div class="main">
      <Header />
      <slot />
      <Footer />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "../components/Navbar.vue"
import Footer from "../components/Footer.vue"
import Header from "../components/Header.vue"

export default {
  components: {
    Navbar,
    Footer,
    Header
  }
}
</script>
