import Vue from 'vue'

import App from './App.vue'
import VueRouter from 'vue-router'
import VueAxios from 'vue-axios'
import axios from 'axios'
import store from './store'
import Vuelidate from 'vuelidate'
import Routes from './routes'
import Member from './layouts/Member.vue'
import Default from './layouts/Default.vue'
import Login from './layouts/Login.vue'
import config from './config'
import './roles'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import CKEditor from 'ckeditor4-vue'
import Loading from './components/Loading'

Vue.router = Routes
Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(Vuelidate)
Vue.prototype.$config = config
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(CKEditor)


Vue.component('member-layout', Member)
Vue.component('default-layout', Default)
Vue.component('login-layout', Login)
Vue.component('loading', Loading)

new Vue({
  el: '#app',
  router: Routes,
  store,
  render: h => h(App),
})


